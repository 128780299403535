import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';

document.addEventListener("turbolinks:load", function () {
  if($('body.time_slots.index').length > 0) {
    var $selectAll = $("input[name='select_all']")

    $selectAll.change(function(event) {
      var $currentTarget = $(event.currentTarget)
      var targetName = $currentTarget.attr('data-target-name')
      $("input[name='" + targetName + "']").prop( "checked", $currentTarget.is(':checked') )
    });

  }
});
