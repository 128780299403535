import { FetchRequest } from '@rails/request.js'

 export default class TimeSlotRequest {
  constructor() { }

  static async getTimeSlots(space_id, start_at) {
    const urlParser = new URL(window.location.origin + '/dashboard/spaces/' + space_id);
    urlParser.searchParams.set("start_at", start_at);

    const request = new FetchRequest('get', urlParser.href , {headers: {'Content-Type': 'application/json', 'Accept': 'application/json'}})
    const response = await request.perform()
    if (response.ok) {
      const body = await response.json

      return body
    }
    if (!response.ok) {
      const body = await response.text
      alert(body)
      return null;
    }
  }

  static async create(body) {
    const request = new FetchRequest('post', window.location.origin + '/dashboard/time_slots', {body: JSON.stringify(body), headers: {'Content-Type': 'application/json', 'Accept': 'application/json'}})
    const response = await request.perform()
    if (response.ok) {
      const respBody = await response.json
      var createdEvent = {
        id: respBody.id + "",
        start: respBody.started_at,
        end: respBody.ended_at,
        color: respBody.color,
        title: respBody.title,
        editable: true,
        user_id: body.time_slot.user_id
      }

      return createdEvent;

    } else {
      const body = await response.text
      alert(body)
      return null;
    }
  }

  static async update(body) {
    const request = new FetchRequest('put', window.location.origin + '/dashboard/time_slots/' + body.time_slot.id, {body: JSON.stringify(body), headers: {'Content-Type': 'application/json', 'Accept': 'application/json'}})
    const response = await request.perform()

    if (response.ok) {
      const respBody = await response.json
      var updatedEvent = {
        id: respBody.id + "",
        start: respBody.started_at,
        end: respBody.ended_at,
        color: respBody.color,
        title: respBody.title,
        editable: true,
        user_id: body.time_slot.user_id
      }

      return updatedEvent

    } else {
      const respBody = await response.text
      alert(JSON.parse(respBody).message)
    }
  }

  static async delete(id) {
    const request = new FetchRequest('delete', window.location.origin + '/dashboard/time_slots/' + id, {headers: {'Content-Type': 'application/json', 'Accept': 'application/json'}})
      const response = await request.perform()
      if (response.ok) {
        const body = await response.json

        return true;
      } else {
        const body = await response.text
        alert(JSON.parse(body).message)

        return false;
      }
  }
}



// constructor(name, salary) {
  //   this.name = name;
  //   this.salary = salary;
  // }

  // increaseSalary() {
  //   this.salary += 100;
  // }

  // const employee = new Employee('Alice', 100);

  // console.log(employee.name); // 👉️ 'Alice'
  // console.log(employee.salary); // 👉️ 100

  // employee.increaseSalary();

  // console.log(employee.salary); // 👉️ 200
