import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';
import TimeSlotRequest from '../requests/time_slot.js';

document.addEventListener("turbolinks:load", function () {
  if($('body.spaces.show').length > 0) {
    var calendarEl = document.getElementById('calendar');
    const parser = new URL(window.location);

    var initialView = parser.searchParams.get('view') || ($(window).width() < 765 ? 'timeGridDay' : 'timeGridWeek')

    var selectable = calendarEl.getAttribute('data-create-events') == 'true'
    var slotMinTime = calendarEl.getAttribute('data-min-slot')
    var slotMaxTime = calendarEl.getAttribute('data-max-slot')
    var weekends = calendarEl.getAttribute('data-weekends') == "true"
    var freeRentals = calendarEl.getAttribute('data-free-rentals') == "true"


    if(calendarEl.getAttribute('data-space-type') == 'space') {
      let calendar = new Calendar(calendarEl, {
        // schedulerLicenseKey: 'CC-Attribution-NonCommercial-NoDerivatives',
        plugins: [ dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin ],
        themeSystem: 'bootstrap5',
        initialView: initialView,
        // initialView: 'resourceTimeline',
        longPressDelay: 200,
        height: 'auto',
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'timeGridDay,timeGridWeek' // dayGridMonth
        },
        timeZoneParam: 'UTC',
        droppable: true,
        selectable: selectable,
        displayEventTime: true,
        weekends: weekends,
        editable: true,
        eventTimeFormat: {hour: '2-digit', minute: '2-digit', hour12: false},
        slotLabelFormat: {hour: '2-digit', minute: '2-digit', hour12: false, meridiem: 'short'},
        slotMinTime: slotMinTime,
        slotMaxTime: slotMaxTime,
        initialDate: calendarEl.getAttribute('data-initial-date'),
        dateClick: function(info) {
          // alert('clicked ' + info.dateStr + ' on resource ' + info.resource);
        },
        select: function(info) {
          let event_name = prompt("Event Name");
          var event = {
            title: event_name,
            start: info.startStr,
            end: info.endStr
          }
          createTimeSlot(calendar, event)
        },
        datesSet: function(dateInfo) {
          const parser = new URL(window.location);
          var calendarStartDate = dateInfo.startStr.split("T")[0]
          if(calendarStartDate != decodeURIComponent(parser.searchParams.get("start_at")) || calendar.view.type != parser.searchParams.get("view")) {
            parser.searchParams.set("start_at", calendarStartDate);
            parser.searchParams.set("view", calendar.view.type)
            window.location.href = parser.href;
          }
        },
        eventChange: function(eventChangeInfo) {
          updateTimeSlot(calendar, eventChangeInfo.event)
        },
        eventResize: function(eventResizeInfo) { // https://fullcalendar.io/docs/eventResize

        },
       eventContent: function(info) {
          return {
            html: '<div class="fc-event-main-frame"><div class="fc-event-time">' + info.timeText + '<button type="button" class="btn-close float-end m-1" aria-label="Close"></button></div><div class="fc-event-title-container"><div class="fc-event-title fc-sticky">' + info.event.title + '</div></div></div>'
          };
        },
        eventDidMount: function(eventDidMountInfo) {
          console.log(eventDidMountInfo.event)
          var $deleteBtn = $(eventDidMountInfo.el).find('button')
          if(eventDidMountInfo.event.startEditable) {
            $deleteBtn.on( "click touchend", function(argument) {
              deleteTimeSlot(calendar, eventDidMountInfo.event)
            })
          } else {
            $deleteBtn.remove()
          }
        },

      });


      calendar.render();

      getEvents(calendar)
    }
  }
});

async function getEvents(calendar) {
  var space_id = $('#calendar').attr('space-id')
  const parser = new URL(window.location);

  var events = await TimeSlotRequest.getTimeSlots(space_id, parser.searchParams.get("start_at"))

  if(events != null) {
    $.each(events, function( index, value ) {
      // var val = value
      // val['resourceId'] = 1
      calendar.addEvent(value)
    });
  }
};


async function createTimeSlot(calendar, event) {
  var body = {
    time_slot: {
      name: event.title,
      started_at: event.start,
      ended_at: event.end,
      space_id: $('#calendar').attr('space-id'),
      user_id: null
    }
  }
  var createdEvent = await TimeSlotRequest.create(body)

  if(createdEvent != null) {
    calendar.addEvent(createdEvent)
  }
};

async function updateTimeSlot(calendar, event) {
  var body = {
    time_slot: {
      id: event.id,
      started_at: event.start,
      ended_at: event.end
    }
  }

  var updatedEvent = await TimeSlotRequest.update(body)

  if(updatedEvent != null) {
    var ev = calendar.getEventById(updatedEvent.id + "")
    ev.remove()
    calendar.addEvent(updatedEvent)
  }
};

async function deleteTimeSlot(calendar, event) {
  var isDeleted = await TimeSlotRequest.delete(event.id)

  if(isDeleted) {
    var ev = calendar.getEventById(event.id + "")
    ev.remove()
  }
};
