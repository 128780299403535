import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';
import { FetchRequest } from '@rails/request.js'


document.addEventListener("turbolinks:load", function () {
  if($('body.spaces.show').length > 0) {

    var userId = $('body').attr('user-id')
    var calendarEl = document.getElementById('calendar');
    const parser = new URL(window.location);

    var initialView = parser.searchParams.get('view') || ($(window).width() < 765 ? 'timeGridDay' : 'timeGridWeek')
    var slotMinTime = calendarEl.getAttribute('data-min-slot')
    var slotMaxTime = calendarEl.getAttribute('data-max-slot')
    var weekends = calendarEl.getAttribute('data-weekends') == "true"


    if(calendarEl.getAttribute('data-space-type') == 'meeting') {
      let calendar = new Calendar(calendarEl, {
        plugins: [ dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin ],
        themeSystem: 'bootstrap5',
        initialView: initialView,
        longPressDelay: 600,
        height: 'auto',
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'timeGridDay,timeGridWeek,dayGridMonth' // dayGridMonth
        },
        timeZoneParam: 'UTC',
        selectable: true,
        droppable: true,
        displayEventTime: true,
        weekends: weekends,
        nowIndicator: true,
        eventTimeFormat: {hour: '2-digit', minute: '2-digit', hour12: false},
        slotLabelFormat: {hour: '2-digit', minute: '2-digit', hour12: false, meridiem: 'short'},
        slotMinTime: slotMinTime,
        slotMaxTime: slotMaxTime,
        initialDate: calendarEl.getAttribute('data-initial-date'),
        // dateClick: function(info) {
        //   alert('clicked ' + info.dateStr + ' on resource ' + info.resource);
        // },
        select: function(info) {
          var event = {
            start: info.startStr,
            end: info.endStr,
            user_id: userId
          }
          createTimeSlot(calendar, event)
        },
        datesSet: function(dateInfo) {

        },
        eventResize: function(eventResizeInfo) { // https://fullcalendar.io/docs/eventResize

        },
        eventDidMount: function(info) {

        },
        datesSet: function(dateInfo) {
          const parser = new URL(window.location);
          if(dateInfo.startStr != decodeURIComponent(parser.searchParams.get("start_at")) || calendar.view.type != parser.searchParams.get("view")) {
            parser.searchParams.set("start_at", dateInfo.startStr);
            parser.searchParams.set("view", calendar.view.type)
            window.location.href = parser.href;
          }
        },
        eventClick: function(eventClickInfo) {
        },
        eventDidMount: function(eventDidMountInfo) {
          var $deleteBtn = $(eventDidMountInfo.el).find('button')
          if(eventDidMountInfo.event.extendedProps.user_id == userId) {
            $deleteBtn.on( "click touchend", function(argument) {
              deleteTimeSlot(calendar, eventDidMountInfo.event)
            })
          } else {
            $deleteBtn.remove()
          }
        },
        eventChange: function(eventChangeInfo) {
          updateTimeSlot(calendar, eventChangeInfo.event)
        },
        eventContent: function(info) {
          return {
            html: '<div class="fc-event-main-frame"><div class="fc-event-time">' + info.timeText + '<button type="button" class="btn-close float-end m-1" aria-label="Close"></button></div><div class="fc-event-title-container"><div class="fc-event-title fc-sticky">' + info.event.title + '</div></div></div>'
          };
        }
      });

      calendar.render();

      getEvents(calendar)
    }
  }
});

async function getEvents(calendar) {
  const request = new FetchRequest('get', window.location.href, {headers: {'Content-Type': 'application/json', 'Accept': 'application/json'}})
    const response = await request.perform()
    if (response.ok) {
      const body = await response.json

      $.each(body, function( index, value ) {
        calendar.addEvent(value)
      });
    }
    if (!response.ok) {
      const body = await response.text
      alert(body)
    }
};

async function createTimeSlot(calendar, event) {
  var body = {
    time_slot: {
      started_at: event.start,
      ended_at: event.end,
      space_id: $('#calendar').attr('space-id')
    }
  }
  const request = new FetchRequest('post', window.location.origin + '/dashboard/time_slots', {body: JSON.stringify(body), headers: {'Content-Type': 'application/json', 'Accept': 'application/json'}})
    const response = await request.perform()
    if (response.ok) {
      const body = await response.json
      var createdEvent = {
        id: body.id + "",
        start: body.started_at,
        end: body.ended_at,
        color: body.color,
        title: body.title,
        editable: true,
        user_id: event.user_id
      }
      calendar.addEvent(createdEvent)

    } else {
      const body = await response.text
      alert(body)
    }
};

async function updateTimeSlot(calendar, event) {
  var body = {
    time_slot: {
      id: event.id,
      started_at: event.start,
      ended_at: event.end,
    }
  }
  const request = new FetchRequest('put', window.location.origin + '/dashboard/time_slots/' + event.id, {body: JSON.stringify(body), headers: {'Content-Type': 'application/json', 'Accept': 'application/json'}})
    const response = await request.perform()
    if (response.ok) {
      const body = await response.json
      var createdEvent = {
        id: body.id + "",
        start: body.started_at,
        end: body.ended_at,
        color: body.color,
        title: body.title,
        editable: true,
        user_id: event.extendedProps.user_id
      }
      var ev = calendar.getEventById(body.id + "")
      ev.remove()
      calendar.addEvent(createdEvent)

    } else {
      const body = await response.text
      alert(JSON.parse(body).message)
    }
};

async function deleteTimeSlot(calendar, event) {
  const request = new FetchRequest('delete', window.location.origin + '/dashboard/time_slots/' + event.id, {headers: {'Content-Type': 'application/json', 'Accept': 'application/json'}})
    const response = await request.perform()
    if (response.ok) {
      const body = await response.json
      var ev = calendar.getEventById(event.id + "")
      ev.remove()
    } else {
      const body = await response.text
      alert(JSON.parse(body).message)
    }
};
